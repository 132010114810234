import { Dropdown } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import Logo from "../../assets/rsvp-global-logo.png";
import { getOrganizationDocById, logOut } from "../../firebase";

function DashboardLayout() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(false);
  const [credit, setCredit] = useState(0);
  // signOut function
  const handleLogout = async (e) => {
    await logOut();
    navigate("/giris");
  };

  useEffect(() => {
    if (currentUser?.organizationId) {
      getOrganizationDocById(currentUser.organizationId).then(
        (organizationData) => {
          setCredit(organizationData.credits);
        }
      );
    }
  }, [currentUser?.organizationId]);

  const mobilSideBarButton = () => {
    setSideBar(!sideBar);
  };

  return (
    <>
      <div className="antialiased bg-gray-50 dark:bg-gray-900">
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center">
              {/* Logo */}
              <Link
                to="/etkinlikler"
                className="flex items-center justify-between sm:mr-4"
              >
                <img
                  src={Logo}
                  className="sm:mr-3 h-8"
                  alt="RSVP Global Logo"
                />
              </Link>
            </div>
            <div className="flex items-center lg:order-2">
              {currentUser?.role === "event" ? null : (
                <Link
                  type="button"
                  to="kredi"
                  className=" sm:inline-flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-3 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 sm:text-lg	"
                >
                  Kredi: {credit}
                </Link>
              )}

              {/* <!-- Dropdown menu --> */}
              <Dropdown
                arrowIcon={false}
                inline
                label={
                  <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <span className="font-medium text-gray-600 dark:text-gray-300 uppercase">
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 11 14H9a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 10 19Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    </span>
                  </div>
                }
              >
                <Dropdown.Item>{currentUser?.email}</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Çıkış Yap</Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </nav>

        {/* Content */}
        <main className="p-4 pt-20 bg-gray-50 h-screen">
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default DashboardLayout;
