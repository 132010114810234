import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { getPaymentDoc } from "../../firebase";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiError, BiTime } from "react-icons/bi";

function PaymentResult() {
  const { paymentId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    getPaymentDoc(paymentId, currentUser.uid).then((payment) => {
      if (payment == null) {
        return navigate("/unauthorized");
      }
      switch (payment.paymentStatus) {
        case "pending":
          setMessage("Ödeme işlemi bekleniyor.");
          setColor("yellow");
          setIcon(<BiTime className="text-yellow-600" />);
          break;
        case "failed":
          setMessage("Ödeme işlemi başarısız oldu.");
          setColor("red");
          setIcon(<BiError className="text-red-600" />);
          break;
        case "success":
          setMessage("Ödeme işlemi başarıyla tamamlandı!");
          setColor("green");
          setIcon(<AiOutlineCheckCircle className="text-green-600" />);
          break;
        default:
          setMessage("Bilinmeyen bir durum oluştu.");
          setColor("gray");
      }
    });
  }, [paymentId, navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className={`text-${color}-600 text-7xl mb-4`}>{icon}</div>
      <h1 className={`text-3xl font-semibold mb-4 text-${color}-600`}>
        {message}
      </h1>
      <Link
        to="/etkinlikler"
        className={` text-primary-500 px-4 py-2 rounded text-xl font-bold hover:text-primary-600 transition duration-300`}
      >
        Etkinlik Sayfasına Dön
      </Link>
    </div>
  );
}

export default PaymentResult;
