import { initializeApp } from "firebase/app";

import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import {
  GeoPoint,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Register users Function
async function register(eventName, organizationId) {
  // create events collection
  const eventRef = await addDoc(collection(db, "events"), {
    eventName: eventName,
  });
  const randomNumber = Math.floor(Math.random() * 10000);
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    `random${randomNumber}@rsvp-global.com`,
    "random2"
  );

  // create event user data
  await setDoc(doc(db, "users", userCredential.user.uid), {
    email: userCredential.user.email,
    eventId: eventRef.id,
    organizationId: organizationId,
  });
}

// Login Function
async function signIn(email, password, subdomain) {
  try {
    const userData = await getUserDocByEmail(email);
    if (userData && userData.orgSubDomain === subdomain) {
      await signInWithEmailAndPassword(auth, email, password);
      return { success: true };
    } else {
      return { success: false, message: "Hatalı giriş yaptınız!" };
    }
  } catch (error) {
    return { success: false, message: "Hatalı giriş yaptınız!" };
  }
}

// signOut function
async function logOut() {
  await signOut(auth);
}

async function getUser(user) {
  const userDocRef = doc(db, "users", user.uid);
  const userDocSnap = await getDoc(userDocRef);
  const userDocData = userDocSnap.data();

  const userData = {
    uid: user.uid,
    email: user.email,
    accessToken: user.accessToken,
    organizationId: userDocData.organizationId,
    eventId: userDocData?.eventId ?? null,
    role: userDocData?.eventId ? "event" : "organization",
  };

  return userData;
}

async function getUserDocByEmail(email) {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("email", "==", email));

  try {
    const querySnapshot = await getDocs(q);
    let userData = null;

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0]; // Assuming email is unique and only one document is returned
      userData = { id: userDoc.id, ...userDoc.data() };

      // Check if organizationId is present in the user's data
      if (userData.organizationId) {
        const orgDocRef = doc(db, "organizations", userData.organizationId);
        const orgDocSnap = await getDoc(orgDocRef);

        if (orgDocSnap.exists()) {
          // Add the organization domain to userData
          const orgData = orgDocSnap.data();
          userData.orgSubDomain = orgData.domain; // Assuming 'domain' is the field name in the organizations collection
        }
      }
    }

    return userData;
  } catch (error) {
    console.error("Error getting documents: ", error);
  }
}

async function getEvents(organizationId) {
  const eventsRef = collection(db, "events");
  const q = query(eventsRef, where("organizationId", "==", organizationId));

  const eventsArray = [];

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      eventsArray.push({ id: doc.id, ...doc.data() });
    });
  } catch (error) {
    console.error("Error fetching events:", error);
  }

  return eventsArray;
}

async function getOrganizationDocById(organizationId) {
  const organizationsRef = doc(db, "organizations", organizationId);
  const organizations = await getDoc(organizationsRef);
  return organizations.data();
}

async function createEvent(newEvent, currentUser) {
  return await addDoc(collection(db, "events"), {
    eventName: newEvent.eventName,
    credits: parseFloat(newEvent.credit),
    creditsRemained: parseFloat(newEvent.credit),
    organizationId: currentUser.organizationId,
    userId: currentUser.uid,
    category: newEvent.category,
    createdAt: serverTimestamp(),
    status: newEvent.status,
    groomName: newEvent.groomName,
    brideName: newEvent.brideName,
    eventDate: newEvent.eventDate,
    eventFileUrl: newEvent.eventFileUrl,
    eventFileType: newEvent.eventFileType,
    locationName: newEvent.locationName,
    location: new GeoPoint(newEvent.location.lat, newEvent.location.lng),
  });
}

async function getEvent(eventId) {
  const eventDocRef = doc(db, "events", eventId);

  try {
    const eventDocSnap = await getDoc(eventDocRef);
    if (!eventDocSnap.exists()) {
      console.log("No such event!");
      return null;
    }

    const eventData = eventDocSnap.data();

    // Query the 'guests' subcollection
    const guestsCollectionRef = collection(eventDocRef, "guests");
    const q = query(guestsCollectionRef, orderBy("updatedAt", "desc"));
    const querySnapshot = await getDocs(q);

    // Map over the documents in the subcollection to get their data
    eventData.guests = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return eventData;
  } catch (error) {
    return null;
  }
}

async function manageGuestInEvent(eventId, guestData) {
  const guestDocRef = doc(db, "events", eventId, "guests", guestData.phone);

  await setDoc(guestDocRef, guestData);
}

async function deleteGuestFromEvent(eventId, guestId) {
  const guestDocRef = doc(db, "events", eventId, "guests", guestId);

  await deleteDoc(guestDocRef);
}

async function updateEventStatus(eventId, status) {
  // Create a reference to the specific event document
  const eventDocRef = doc(db, "events", eventId);

  // Update the event's status to "queued"
  await updateDoc(eventDocRef, {
    status: status,
  });
}

async function getPaymentDoc(paymentId, userId) {
  const paymentRef = doc(db, "payments", paymentId);

  try {
    const paymentDocSnap = await getDoc(paymentRef);
    if (!paymentDocSnap.exists()) {
      return null;
    }
    const paymentDocData = paymentDocSnap.data();

    if (paymentDocData.userId === userId) {
      return { id: paymentDocData.id, ...paymentDocData };
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

async function deleteEvent(eventId) {
  const eventDocRef = doc(db, "events", eventId);
  const eventDocSnap = await getDoc(eventDocRef);

  if (!eventDocSnap.exists()) {
    return false;
  } else {
    const subCollectionRef = collection(db, "events", eventId, "guests");
    const subCollectionQuery = query(subCollectionRef);
    const subCollectionSnapshot = await getDocs(subCollectionQuery);

    // Create a batch for deletion
    const batch = writeBatch(db);

    // Add each subcollection document to the batch delete
    subCollectionSnapshot.forEach((subDoc) => {
      batch.delete(subDoc.ref);
    });

    // Delete the event document
    batch.delete(eventDocRef);

    // Commit the batch
    await batch.commit();

    return true;
  }
}

async function deleteGuestAndConversation(eventId, guestId) {
  const guestDocRef = doc(db, "events", eventId, "guests", guestId);
  await deleteDoc(guestDocRef);

  // Delete the conversation by using where guestPhone is equal to guestId and eventId is equal to eventId
  const conversationRef = collection(db, "conversations");
  const q = query(
    conversationRef,
    where("guestPhone", "==", guestId),
    where("eventId", "==", eventId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.docs.map(async (cDoc) => {
    await deleteDoc(cDoc.ref);

    // Delete the messages by using the conversationId where conversationId and eventId is equal to eventId
    const messagesRef = collection(db, "messages");
    const q = query(
      messagesRef,
      where("conversationId", "==", cDoc.id),
      where("eventId", "==", eventId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map(async (mDoc) => {
      await deleteDoc(mDoc.ref);
    });
  });
}

export {
  app,
  auth,
  createEvent,
  db,
  deleteEvent,
  deleteGuestAndConversation,
  deleteGuestFromEvent,
  getEvent,
  getEvents,
  getOrganizationDocById,
  getPaymentDoc,
  getUser,
  logOut,
  manageGuestInEvent,
  register,
  signIn,
  storage,
  updateEventStatus,
};
