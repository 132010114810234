import React, { useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

function Places({ setSelected }) {
  const [libraries] = useState(["places"]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Yükleniyor...</div>;

  // setSelected'ı map'e gönderiyor
  return <Map setSelected={setSelected} />;
}

function Map(props) {
  const [selected, setSelected] = useState({ lat: 41.015137, lng: 28.97953 });
  const handleMarkerSelect = (position) => {
    setSelected(position);

    // props.setSelected'a lat ve lng değerlerini gönderiyor
    props.setSelected(position);
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
  };

  return (
    <>
      <div className="places-container">
        <PlacesAutocomplete setSelected={handleMarkerSelect} />
      </div>

      <GoogleMap
        zoom={14}
        center={selected}
        mapContainerClassName="map-container w-full h-[200px]"
        options={options}
      >
        {selected && (
          <Marker position={{ lat: selected.lat, lng: selected.lng }} />
        )}
      </GoogleMap>
    </>
  );
}

function PlacesAutocomplete({ setSelected }) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "TR" },
    },
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
  };

  return (
    <div className="relative w-full mb-8">
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        id="venueLocation"
        name="venueLocation"
        type="text"
        required
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 datepicker-input"
      />
      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
        <svg
          className="w-5 h-5 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 16 20"
        >
          <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
        </svg>
      </div>

      {status === "OK" && (
        <ul className="bg-white absolute z-10 border-gray-300 border-2 mt-2 w-full">
          {data.map(({ place_id, description }) => (
            <li
              key={place_id}
              onClick={() => handleSelect(description)}
              className="border-gray-300 border-2 cursor-pointer"
            >
              {description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Places;
