// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, getUser } from "./firebase";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in, set the user in the context
        const userData = await getUser(user);
        setCurrentUser(userData);
      } else {
        // No user is signed in, reset the context
        setCurrentUser(null);
      }
      setLoading(false); // Set loading to false after user state is determined
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {/* Render children only when not loading */}
      {!loading && children}
    </AuthContext.Provider>
  );
};
