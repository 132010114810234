import axios from "axios";
import { Dropdown } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { useAuth } from "../../AuthContext";
import {
  deleteGuestAndConversation,
  deleteGuestFromEvent,
  getEvent,
  manageGuestInEvent,
  updateEventStatus,
} from "../../firebase";
import isDataEmpty from "../../utils/helper";

function EventDetail() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { currentUser } = useAuth();
  const excelUploadElement = document.getElementById("excel-upload");

  const [loading, setLoading] = useState(true);

  const [event, setEvent] = useState(null);

  const [showSendConfirmationModal, setShowSendConfirmationModal] =
    useState(false);

  const [showGuestDrawer, setShowGuestDrawer] = useState(false);
  const [guestDrawerType, setGuestDrawerType] = useState("add");
  const emptyGuest = {
    name: "",
    phone: "",
    maxNumberOfGuests: 1,
    numberOfGuests: 0,
    status: "pending",
    updatedAt: "",
    lang: "tr",
  };
  const [guestFormData, setGuestFormData] = useState(emptyGuest);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(null);

  const handleDeleteModal = (guestId) => {
    setDeleteModalData(guestId);
    setShowDeleteModal(true);
  };

  const handleDeleteButton = async () => {
    if (deleteModalData != null) {
      await deleteGuestAndConversation(eventId, deleteModalData);
      // remove deleted guest from filtered guests
      const updatedGuests = event.guests.filter(
        (guest) => guest.id !== deleteModalData
      );
      setEvent({ ...event, guests: updatedGuests });

      toast.success("Davetli başarıyla silindi.");
    } else {
      toast.error("Silme işlemi sırasında bir hata oluştu.");
    }
    setShowDeleteModal(false);
    setDeleteModalData(null);
  };

  useEffect(() => {
    getEvent(eventId).then((event) => {
      if (event == null) {
        return navigate("/unauthorized");
      }
      setEvent(event);
      setLoading(false);
    });
  }, [eventId, navigate]);

  const [statsLoading, setStatsLoading] = useState(false);
  const [stats, setStats] = useState({
    totalGuests: 0,
    waitingForInvitation: 0,
    participated: 0,
    notParticipated: 0,
  });

  //  filter state
  const [filter, setFilter] = useState({
    pending: false,
    invitationSent: false,
    attendanceInquiry: false,
    attendanceAcceptedWithGuests: false,
    attendanceRejected: false,
    search: "",
  });
  const [filteredGuests, setFilteredGuests] = useState(event?.guests);

  useEffect(() => {
    setStatsLoading(true);
    let totalGuests = 0;
    let waitingForInvitation = 0;
    let participated = 0;
    let notParticipated = 0;

    if (event != null) {
      event.guests.forEach((guest) => {
        totalGuests += guest.maxNumberOfGuests;

        switch (guest.status) {
          case "pending":
            waitingForInvitation += guest.maxNumberOfGuests;
            break;
          case "attendance-accepted-with-guests":
            // participated++;
            participated += guest.numberOfGuests;
            // notParticipated += guest.maxNumberOfGuests - guest.numberOfGuests;
            break;
          case "attendance-accepted-with-guests-infos":
            // participated++;
            participated += guest.numberOfGuests;
            // notParticipated += guest.maxNumberOfGuests - guest.numberOfGuests;
            break;
          case "attendance-rejected":
            notParticipated += guest.maxNumberOfGuests;
            break;
          default:
            // Handle other statuses if needed
            break;
        }
        setStats({
          totalGuests: totalGuests,
          waitingForInvitation: waitingForInvitation,
          participated: participated,
          notParticipated: notParticipated,
        });
      });
    }
    setStatsLoading(false);
  }, [event]);

  const handleGuestButton = (guestId = null) => {
    if (guestId != null) {
      setGuestDrawerType("edit");
      const currentGuest = event.guests.find((p) => p.id === guestId);
      setGuestFormData(currentGuest);
    } else {
      setGuestDrawerType("add");
      setGuestFormData(emptyGuest);
      if (excelUploadElement) {
        excelUploadElement.value = "";
      }
    }
    setShowGuestDrawer(true);
  };

  let temporaryExcelData = null;
  const onChangeExcelFile = async (e) => {
    const file = e.target.files[0];

    const allowedExtensions = ["xlsx", "xls"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(
        "Geçersiz dosya uzantısı. Lütfen .xlsx veya .xls dosyası yükleyin."
      );
      e.target.value = null;
      return;
    }

    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils
        .sheet_to_json(worksheet, { header: 1 })
        .slice(1);

      temporaryExcelData = jsonData;
    };

    reader.readAsArrayBuffer(file);
  };

  const submitGuestForm = async () => {
    if (temporaryExcelData != null) {
      await setGuestByExcelData();
    } else {
      await setGuest();
    }
  };

  const setGuestByExcelData = async () => {
    if (!temporaryExcelData) {
      toast.error("Lütfen önce bir Excel dosyası yükleyin.");
      excelUploadElement.value = "";
      return;
    }

    let newGuestsArray = [];
    for (const data of temporaryExcelData) {
      if (isDataEmpty(data)) {
        toast.error(
          "Dosyanızda davetli bilgileri eksik, lütfen tüm alanları doldurunuz."
        );
        excelUploadElement.value = "";
        return;
      }

      // 0: isim, 1: tel, 2: kisi sayısı, 3: dil

      const phone = data[1].toString().trim();

      const guestData = {
        name: data[0],
        phone: phone,
        maxNumberOfGuests: data[2],
        numberOfGuests: 0,
        status: "pending",
        createdAt: new Date(),
        updatedAt: new Date(),
        lang: data[3],
        messageStatus: null,
      };

      if (guestData.maxNumberOfGuests < 0) {
        toast.error("Lütfen 1'den az davetli girmeyiniz.");
      } else {
        try {
          // add guest data to guest subcollection in related event
          await manageGuestInEvent(eventId, guestData);

          newGuestsArray = [
            { ...guestData, id: guestData.phone },
            ...newGuestsArray,
          ];
        } catch (error) {
          toast.error(
            `Dosyanızda davetli bilgileri eksik, lütfen tüm alanları doldurunuz.`
          );
          excelUploadElement.value = "";
        }
      }
    }

    // Update the local state with the new guest
    const updatedGuests = [...newGuestsArray, ...(event.guests || [])];

    setEvent({ ...event, guests: updatedGuests });

    // Close the guest drawer
    setShowGuestDrawer(false);

    toast.success("Veriler başarıyla eklendi.");
  };

  const setGuest = async () => {
    const phone = guestFormData.phone.toString().trim();
    const guestData = {
      name: guestFormData.name,
      phone: phone,
      maxNumberOfGuests: guestFormData.maxNumberOfGuests,
      numberOfGuests:
        guestFormData.status === "attendance-accepted-with-guests"
          ? guestFormData.numberOfGuests
          : guestFormData.status === "attendance-rejected"
          ? 0
          : guestFormData.numberOfGuests || 0,
      status: guestFormData.status,
      lang: guestFormData.lang,
      updatedAt: new Date(),
      messageStatus: null,
    };
    if (!guestFormData.id) {
      guestData.createdAt = new Date();
    }

    let control = false;
    if (guestData.name === "") {
      toast.error("Lütfen bir isim giriniz.");
    } else if (!/^\d+$/.test(guestData.phone)) {
      toast.error("Lütfen bir numara giriniz.");
    } else if (guestData.maxNumberOfGuests <= 0) {
      toast.error("Lütfen 1'den az davetli girmeyiniz.");
    } else if (isNaN(guestData.maxNumberOfGuests)) {
      toast.error("Lütfen bir sayı giriniz.");
    } else if (guestData.status === "attendance-accepted-with-guests") {
      if (guestData.numberOfGuests > guestData.maxNumberOfGuests) {
        toast.error("Katılacak Kişi Sayısı davetli sayısından fazla olamaz.");
      } else if (guestData.numberOfGuests <= 0) {
        toast.error("Katılacak Kişi Sayısı 1'den küçük olamaz.");
      } else if (isNaN(guestData.numberOfGuests)) {
        toast.error("Lütfen bir sayı giriniz.");
      } else {
        control = true;
      }
    } else if (guestData.status === "attendance-rejected") {
      if (isNaN(guestData.numberOfGuests)) {
        guestData.numberOfGuests = 0;
      } else {
        control = true;
      }
    } else if (
      guestData.status !== "attendance-rejected" &&
      guestData.status !== "attendance-accepted-with-guests"
    ) {
      guestData.numberOfGuests = 0;
      control = true;
    }

    if (control === true) {
      try {
        let updatedGuests = event.guests || [];

        // edit edilen kaydın tel no bilgisi değişti ise, eski veriyi sil ve state'den kaldır
        if (guestFormData.id) {
          updatedGuests = updatedGuests.filter(
            (guest) => guest.id !== guestFormData.id
          );
          if (guestFormData.id !== guestData.phone) {
            await deleteGuestFromEvent(eventId, guestFormData.id);
          }
        }

        // set guest data to guest subcollection in related event
        await manageGuestInEvent(eventId, guestData);

        // After adding the new guest, clear the form
        setGuestFormData(emptyGuest);

        // Update the local state with the new guest
        updatedGuests = [
          { ...guestData, id: guestData.phone },
          ...updatedGuests,
        ];

        setEvent({ ...event, guests: updatedGuests });

        // Close the guest drawer
        setShowGuestDrawer(false);

        if (!guestFormData.id) {
          toast.success("Davetli başarıyla eklendi.");
        } else {
          toast.success("Davetli başarıyla güncellendi.");
        }
      } catch (error) {
        toast.error("Eksik alanları doldurunuz.");
        excelUploadElement.value = "";
      }
      control = false;
    }
  };

  const handleSendMessages = async () => {
    if (eventId === "NAkDDOenyfuxvw8qyaWC") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/helen-nikita-hotel-promo-code`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "6w93JkXO0U82VvQ9k2A1") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/meri-matyas-save-the-date`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "3MDMialMHKX3oDLGdKkp") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/eda-adriel-welcome-party-send-location`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "0nOQH72tt5aXOlRS0KJV") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/helen-nikita-hotel-promo-code-reminder`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "L6xftux195fhqLKlpvE1") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/eda-adriel-thank-you`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "3T9AoLPLBOYLyHcFILL6") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/irze-murat-save-the-date`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "xS8JNcisXgeSdGJZtAan") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/busra-kadir-teyit-mesaji`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "E9Myjtrb8mdkvuQ7ltQs") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/irze-murat-cesme-save-the-date`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "w8TgjVFsOpxDy8kjnPnL") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/irze-murat-apology-message`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "iE0s7Z8Su1NDCPwuxiFo") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/yagmur-ibrahim-save-the-date`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "viEOADi5VmzoQsvTNZ1l") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/natali-fercan-save-the-date`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "zhDGrrP9GGKMuIFFLDob") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/helen-nikita-weather-warning`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else if (eventId === "vZHWh8K8BnMVAG386nlp") {
      const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
      setShowSendConfirmationModal(false);
      setLoading(true);
      try {
        const response = await axios.get(
          `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/tugce-sercan-bilgilendirme`,
          {
            headers: {
              Authorization: `Bearer ${BEARER_TOKEN}`,
            },
          }
        );
        toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
        window.location.reload();
      } catch (error) {
        toast.error(`Hata oluştu: ${error}`);
      }
      setLoading(false);
      window.location.reload();
    } else {
      if (event.creditsRemained <= 0) {
        toast.error(
          "Toplam davetli limitine ulaştınız, lütfen bizimle iletişime geçiniz."
        );
      } else if (event.status === "queued") {
        toast.error("Mesaj gönderimleri sıraya alındı, lütfen bekleyiniz.");
      } else if (event.status === "sending") {
        toast.error("Mesaj gönderimleri devam ediyor.");
      } else {
        await updateEventStatus(eventId, "queued");
        setEvent({ ...event, status: "queued" });
      }
      setShowSendConfirmationModal(false);
    }
  };

  // const handleMessageStatus = async () => {
  //   const BEARER_TOKEN = process.env.REACT_APP_FUNCTIONS_API_KEY;
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/get-status?id=${eventId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${BEARER_TOKEN}`,
  //         },
  //       }
  //     );
  //     console.log(response);
  //     toast.success(`Başarıyla gönderildi: ${response?.data?.message}`);
  //   } catch (error) {
  //     toast.error(`Hata oluştu: ${error}`);
  //   }
  //   setLoading(false);
  //   window.location.reload();
  // };

  // Filter
  const applyFilter = () => {
    const filtered = event?.guests.filter((guest) => {
      return (
        ((!filter.pending &&
          !filter.invitationSent &&
          !filter.attendanceInquiry &&
          !filter.attendanceAcceptedWithGuests &&
          !filter.attendanceRejected) ||
          (filter.pending && guest.status === "pending") ||
          (filter.invitationSent && guest.status === "invitation-sent") ||
          (filter.attendanceInquiry && guest.status === "attendance-inquiry") ||
          (filter.attendanceAcceptedWithGuests &&
            (guest.status === "attendance-accepted-with-guests" ||
              guest.status === "attendance-accepted-with-guests-infos")) ||
          (filter.attendanceRejected &&
            guest.status === "attendance-rejected")) &&
        guest.name.toLowerCase().includes(filter.search.toLowerCase())
      );
    });

    setFilteredGuests(filtered);
  };
  useEffect(() => {
    applyFilter();
  }, [filter, event?.guests]);

  return (
    <>
      <Helmet>
        <title>Etkinlik Detay | rsvpglobal.org</title>
      </Helmet>
      <div className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        {loading ? (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-20 z-50">
            <div role="status" className="flex items-center gap-2">
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        ) : (
          <>
            {/* Alert */}
            {event.status === "queued" ||
            event.status === "sending" ||
            event.status === "completed" ? (
              <div
                id="alert-additional-content-4"
                className={`p-4 mb-4 ${
                  event.status === "completed"
                    ? "text-green-800 border-green-300 bg-green-50 dark:text-green-300 dark:border-green-800"
                    : "text-yellow-800 border-yellow-300 bg-yellow-50 dark:text-yellow-300 dark:border-yellow-800"
                } border rounded-lg dark:bg-gray-800 mx-auto max-w-screen-xl px-4 lg:px-12`}
                role="alert"
              >
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-4 h-4 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span className="sr-only">Info</span>
                  {event.status === "queued" ? (
                    <h3 className="text-lg font-medium">KUYRUKTA</h3>
                  ) : event.status === "sending" ? (
                    <h3 className="text-lg font-medium">DEVAM EDIYOR</h3>
                  ) : event.status === "completed" ? (
                    <h3 className="text-lg font-medium">TAMAMLANDI</h3>
                  ) : null}
                </div>
                <div className="mt-2 mb-4 text-sm">
                  {event.status === "queued"
                    ? "Mesaj gönderimleri sıraya alınmıştır, en kısa sürede gönderimler sistem tarafından yapılacaktır."
                    : event.status === "sending"
                    ? "Mesaj gönderimleri devam etmektedir..."
                    : event.status === "completed"
                    ? "Mesaj gönderimleri tamamlanmıştır. Gönderim bekleyen kullanıcılara tekrar gönderim başlatabilirsiniz."
                    : null}
                </div>
              </div>
            ) : null}

            {/* Table Header */}
            <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
              {/* <!-- Start coding here --> */}
              <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-t-lg">
                <div className="px-4 divide-y dark:divide-gray-700">
                  <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4 overflow-auto">
                    {/* Event Name */}
                    <div className="flex flex-1 flex-col gap-1">
                      <nav className="flex" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                          {currentUser.role === "event" ? (
                            <li className="inline-flex items-center">
                              <Link
                                to="#"
                                className="inline-flex items-center text-md font-medium text-gray-700 hover:text-primary-600 dark:text-gray-400 dark:hover:text-white"
                              >
                                <svg
                                  className="w-3 h-3 mr-2.5"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                </svg>
                                {event.eventName}
                              </Link>
                            </li>
                          ) : (
                            <>
                              <li className="inline-flex items-center">
                                <Link
                                  to="/etkinlikler"
                                  className="inline-flex items-center text-md font-medium text-gray-700 hover:text-primary-600 dark:text-gray-400 dark:hover:text-white"
                                >
                                  <svg
                                    className="w-3 h-3 mr-2.5"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                  </svg>
                                  Etkinliklerim
                                </Link>
                              </li>
                              <li aria-current="page">
                                <div className="flex items-center">
                                  <svg
                                    className="w-3 h-3 text-gray-400 mx-1"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 8 14"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                                    />
                                  </svg>
                                  <span className="ml-1 text-md font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                                    {event.eventName}
                                  </span>
                                </div>
                              </li>
                            </>
                          )}
                        </ol>
                      </nav>
                      <div>
                        <h5 className="inline-flex items-center text-md font-medium text-gray-700  dark:text-gray-400 dark:hover:text-white">
                          Son Güncelleme Tarihi: {getFormattedTimestamp()}
                        </h5>
                        <a
                          href=""
                          className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center ml-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                          <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"
                            />
                          </svg>
                          <span className="sr-only">Icon description</span>
                        </a>
                        {/* <button
                          type="button"
                          onClick={() => handleMessageStatus()}
                          className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                        >
                          Durum Sorgula
                        </button> */}
                      </div>
                    </div>
                    {/* guest information */}
                    <ol className="items-center sm:flex">
                      <li className="relative mb-6 sm:mb-0">
                        <div className="flex items-center">
                          <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                            <svg
                              className="w-3 h-3 text-violet-800 dark:text-violet"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 19"
                            >
                              <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z" />
                              <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z" />
                            </svg>
                          </div>
                          <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                        </div>
                        <div className="mt-3 sm:pr-8">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Toplam Davetli
                          </h3>
                          {statsLoading ? (
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          ) : (
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                              {stats.totalGuests} Kişi
                            </p>
                          )}
                        </div>
                      </li>
                      <li className="relative mb-6 sm:mb-0">
                        <div className="flex items-center">
                          <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                            <svg
                              className="w-3 h-3 text-violet-800 dark:text-violet"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M15 18h-2v-3.333a2 2 0 0 0-.4-1.2L10.45 10.6a1 1 0 0 1 0-1.2l2.15-2.867a2 2 0 0 0 .4-1.2V2h2a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2h2v3.333a2 2 0 0 0 .4 1.2L5.55 9.4a1 1 0 0 1 0 1.2L3.4 13.467a2 2 0 0 0-.4 1.2V18H1a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2Z" />
                            </svg>
                          </div>
                          <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                        </div>
                        <div className="mt-3 sm:pr-8">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Davetiye Bekleyenler
                          </h3>
                          {statsLoading ? (
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          ) : (
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                              {stats.waitingForInvitation} Kişi
                            </p>
                          )}
                        </div>
                      </li>
                      <li className="relative mb-6 sm:mb-0">
                        <div className="flex items-center">
                          <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                            <svg
                              className="w-3 h-3 text-violet-800 dark:text-violet"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z" />
                            </svg>
                          </div>
                          <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                        </div>
                        <div className="mt-3 sm:pr-8">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Katılım Durumu
                          </h3>
                          {statsLoading ? (
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          ) : (
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                              {stats.participated} Katılacak /{" "}
                              {stats.notParticipated} Katılmayacak
                            </p>
                          )}
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="flex flex-col items-stretch justify-between py-4 space-y-3 md:flex-row md:items-center md:space-x-3 md:space-y-0 overflow-auto">
                    {/* search bar */}
                    <div className="w-full md:w-1/2">
                      <form className="flex items-center">
                        <label htmlFor="simple-search" className="sr-only">
                          Search
                        </label>
                        <div className="relative w-full">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="simple-search"
                            placeholder="Ara..."
                            value={filter.search}
                            onChange={(e) =>
                              setFilter({
                                ...filter,
                                search: e.target.value,
                              })
                            }
                            className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 min-w-[200px]"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                      <button
                        type="button"
                        onClick={() => handleGuestButton()}
                        className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                      >
                        <svg
                          className="h-3.5 w-3.5 mr-1.5 -ml-1"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          />
                        </svg>
                        Davetli Ekle
                      </button>
                      {/* Send invitations button  */}
                      {(!event.custom ||
                        eventId === "xS8JNcisXgeSdGJZtAan" ||
                        eventId === "3T9AoLPLBOYLyHcFILL6" ||
                        eventId === "E9Myjtrb8mdkvuQ7ltQs" ||
                        eventId === "w8TgjVFsOpxDy8kjnPnL" ||
                        eventId === "iE0s7Z8Su1NDCPwuxiFo" ||
                        eventId === "viEOADi5VmzoQsvTNZ1l" ||
                        eventId === "zhDGrrP9GGKMuIFFLDob" ||
                        eventId === "vZHWh8K8BnMVAG386nlp") && (
                        <button
                          type="button"
                          onClick={() => setShowSendConfirmationModal(true)}
                          className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-white bg-primary-700 border border-gray-200 rounded-lg focus:outline-none hover:bg-primary-800 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                          <svg
                            className="w-4 h-4 mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                          </svg>
                          Davetiyeleri Gönder
                        </button>
                      )}

                      {/* Filter button and filtering options */}
                      <Dropdown
                        arrowIcon={false}
                        inline
                        label={
                          <span
                            id="filterDropdownButton"
                            data-dropdown-toggle="filterDropdown"
                            className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            type="button"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              className="h-4 w-4 mr-1.5 -ml-1 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Filtreler
                            <svg
                              className="-mr-1 ml-1.5 w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                            >
                              <path
                                clipRule="evenodd"
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              />
                            </svg>
                          </span>
                        }
                      >
                        <div
                          id="filterDropdown"
                          className="z-10 w-56 p-3 bg-white rounded-lg dark:bg-gray-700"
                        >
                          <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">
                            Davetli Durumu
                          </h6>
                          <ul
                            className="space-y-2 text-sm"
                            aria-labelledby="dropdownDefault"
                          >
                            <li className="flex items-center">
                              <input
                                id="pending"
                                type="checkbox"
                                onChange={() =>
                                  setFilter({
                                    ...filter,
                                    pending: !filter.pending,
                                  })
                                }
                                checked={filter.pending}
                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              <label
                                htmlFor="pending"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                              >
                                Davetiye Gönderilecek
                              </label>
                            </li>
                            <li className="flex items-center">
                              <input
                                id="invitation-sent"
                                type="checkbox"
                                onChange={() =>
                                  setFilter({
                                    ...filter,
                                    invitationSent: !filter.invitationSent,
                                  })
                                }
                                checked={filter.invitationSent}
                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              <label
                                htmlFor="invitation-sent"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                              >
                                Davetiye Gönderildi
                              </label>
                            </li>
                            <li className="flex items-center">
                              <input
                                id="attendance-inquiry"
                                type="checkbox"
                                onChange={() =>
                                  setFilter({
                                    ...filter,
                                    attendanceInquiry:
                                      !filter.attendanceInquiry,
                                  })
                                }
                                checked={filter.attendanceInquiry}
                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              <label
                                htmlFor="attendance-inquiry"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                              >
                                Katılım Durumu Bekleniyor
                              </label>
                            </li>
                            <li className="flex items-center">
                              <input
                                id="attendance-accepted-with-guests"
                                type="checkbox"
                                onChange={() =>
                                  setFilter({
                                    ...filter,
                                    attendanceAcceptedWithGuests:
                                      !filter.attendanceAcceptedWithGuests,
                                  })
                                }
                                checked={filter.attendanceAcceptedWithGuests}
                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              <label
                                htmlFor="attendance-accepted-with-guests"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                              >
                                Katılacak
                              </label>
                            </li>
                            <li className="flex items-center">
                              <input
                                id="attendance-rejected"
                                type="checkbox"
                                onChange={() =>
                                  setFilter({
                                    ...filter,
                                    attendanceRejected:
                                      !filter.attendanceRejected,
                                  })
                                }
                                checked={filter.attendanceRejected}
                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              <label
                                htmlFor="attendance-rejected"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                              >
                                Katılmayacak
                              </label>
                            </li>
                          </ul>
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Table Body  */}
            <section className="bg-gray-50 dark:bg-gray-900 ">
              <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-b-lg overflow-hidden lg:overflow-visible ">
                  <div className="overflow-x-auto">
                    {event.guests?.length === 0 ? (
                      <div className=" flex justify-center py-5">
                        <p className="text-gray-500 dark:text-gray-400">
                          İlk davetliyi eklemek için{" "}
                          <button
                            type="button"
                            onClick={() => handleGuestButton()}
                            className="font-medium text-primary-600 underline dark:text-blue-500 hover:no-underline"
                          >
                            Buraya
                          </button>{" "}
                          tıklayın.
                        </p>
                      </div>
                    ) : (
                      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-4 py-3">
                              Davetli
                            </th>
                            <th scope="col" className="px-4 py-3">
                              Davetiye Sayısı
                            </th>
                            <th scope="col" className="px-4 py-3">
                              Durum
                            </th>
                            <th scope="col" className="px-4 py-3">
                              Katılacak Kişi Sayısı
                            </th>
                            <th scope="col" className="px-4 py-3 ">
                              Son Güncelleme Tarihi
                            </th>
                            <th scope="col" className="px-4 py-3">
                              <span className="sr-only">Actions</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredGuests?.map((guest, index) => (
                            <tr
                              className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                              key={index}
                            >
                              <th
                                scope="row"
                                className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                <div className="flex items-center">
                                  {guest.name}
                                  <span className="ml-2 bg-gray-100 text-gray-800 text-xs font-medium me-2 px-1 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                    {guest.lang
                                      ? guest.lang.toUpperCase()
                                      : "TR"}
                                  </span>
                                </div>
                              </th>

                              <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <div className="flex items-center">
                                  {guest.maxNumberOfGuests}
                                </div>
                              </td>
                              <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <div className="flex items-center">
                                  {renderStatusBadge(guest.status)}
                                </div>
                                {renderMessageStatusBadge(
                                  eventId,
                                  guest?.messageStatus
                                )}
                              </td>
                              <td className="px-4 py-2 font-medium text-gray-900 dark:text-white">
                                <div className="flex items-center">
                                  {guest.numberOfGuests}
                                </div>
                                {guest.guestsInfos ? (
                                  <div>{guest.guestsInfos}</div>
                                ) : (
                                  <></>
                                )}
                                {guest.status ===
                                  "attendance-accepted-with-guests-infos" &&
                                guest.vehicleResponse ? (
                                  <div>{guest.vehicleResponse}</div>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="px-4 py-2">
                                {" "}
                                {getFormattedTimestamp(guest.updatedAt)}
                              </td>
                              <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <Dropdown
                                  arrowIcon={false}
                                  inline
                                  label={
                                    <span
                                      id="dropdown-button-0"
                                      type="button"
                                      data-dropdown-toggle="dropdown-0"
                                      className="inline-flex items-center p-1 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
                                    >
                                      <svg
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                                      </svg>
                                    </span>
                                  }
                                >
                                  <div
                                    id="dropdown-0"
                                    className="z-10  bg-white divide-y divide-gray-100 rounded w-44 dark:bg-gray-700 dark:divide-gray-600"
                                  >
                                    <ul
                                      className="py-1 text-sm text-gray-700 dark:text-gray-200"
                                      aria-labelledby="dropdown-button-0"
                                    >
                                      <li>
                                        <button
                                          type="button"
                                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                                          onClick={() => {
                                            handleDeleteModal(guest.id);
                                          }}
                                        >
                                          Sil
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </section>

            {/* bg black */}
            {(showGuestDrawer ||
              showSendConfirmationModal ||
              showDeleteModal) && (
              <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-[50]"></div>
            )}

            {/* Guest Drawer Component  */}
            <div
              id="drawer-add-user"
              className={`fixed top-0 right-0  h-screen p-4 overflow-y-auto transition-transform  bg-white w-80 dark:bg-gray-800 z-50 border${
                showGuestDrawer ? "transform-none" : " translate-x-full"
              }`}
              tabIndex="-1"
              aria-labelledby="drawer-right-label"
            >
              <h5
                id="drawer-right-label"
                className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
              >
                <svg
                  className="w-4 h-4 mr-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-2V5a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 1 0 0-2Z" />
                </svg>
                {guestDrawerType === "add" ? "Davetli Ekle" : "Davetli Düzenle"}
              </h5>
              <button
                type="button"
                onClick={() => setShowGuestDrawer(false)}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close menu</span>
              </button>
              <div className="mb-4 space-y-4 xl:col-span-2">
                {/* Users name */}
                <div>
                  <label
                    htmlFor="adUserName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    İsim
                  </label>
                  <input
                    type="text"
                    name="adUserName"
                    id="adUserName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                    value={guestFormData.name}
                    onChange={(e) =>
                      setGuestFormData({
                        ...guestFormData,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                {/* Users Phone */}
                <div>
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Telefon Numarası
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    required
                    value={guestFormData.phone}
                    onChange={(e) =>
                      setGuestFormData({
                        ...guestFormData,
                        phone: e.target.value,
                      })
                    }
                  />
                </div>

                {/* lang */}
                <div>
                  <label
                    htmlFor="lang"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Dil
                  </label>
                  <select
                    id="lang"
                    className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={guestFormData.lang}
                    onChange={(e) => {
                      setGuestFormData({
                        ...guestFormData,
                        lang: e.target.value,
                      });
                    }}
                  >
                    <option value="tr">Türkçe</option>
                    <option value="en">İngilizce</option>
                    <option value="ru">Rusça</option>
                  </select>
                </div>

                {/* number of guests */}
                <div>
                  <label
                    htmlFor="maxNumberOfGuests"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Davetli Sayısı
                  </label>
                  <input
                    type="number"
                    id="maxNumberOfGuests"
                    className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={guestFormData.maxNumberOfGuests}
                    min="1"
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value);
                      setGuestFormData({
                        ...guestFormData,
                        maxNumberOfGuests: inputValue,
                      });
                    }}
                  />
                </div>

                {/* status */}
                <div>
                  <label
                    htmlFor="time"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Durum
                  </label>
                  <select
                    id="time"
                    className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={guestFormData.status}
                    onChange={(e) => {
                      setGuestFormData({
                        ...guestFormData,
                        status: e.target.value,
                      });
                    }}
                  >
                    <option value="pending">Davetiye Gönderilecek</option>
                    <option value="invitation-sent">Davetiye Gönderildi</option>
                    <option value="attendance-inquiry">
                      Katılım Durumu Bekleniyor
                    </option>
                    <option value="attendance-accepted-with-guests">
                      Katılacak
                    </option>
                    <option value="attendance-rejected">Katılmayacak</option>
                  </select>
                </div>
                {/* numberOfGuests */}
                {guestFormData.status === "attendance-accepted-with-guests" && (
                  <div>
                    <label
                      htmlFor="numberOfGuests"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Katılacak Kişi Sayısı
                    </label>
                    <input
                      type="number"
                      id="numberOfGuests"
                      required
                      className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      value={guestFormData.numberOfGuests}
                      onChange={(e) => {
                        const inputValue = parseInt(e.target.value);
                        setGuestFormData({
                          ...guestFormData,
                          numberOfGuests: inputValue,
                        });
                      }}
                    />
                  </div>
                )}
                {guestDrawerType === "add" && (
                  <>
                    <div className="inline-flex items-center justify-center w-full">
                      <hr className="w-64 h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
                      <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
                        <svg
                          className="w-4 h-4 text-gray-700 dark:text-gray-300"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 18 14"
                        >
                          <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                        </svg>
                      </div>
                    </div>

                    <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
                      <div className="sm:col-span-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Davetiye Listesini Yükleyiniz
                        </label>
                        <div className="w-full sm:flex items-start flex flex-col">
                          <div className="w-full flex">
                            <input
                              className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                              aria-describedby="file_input_help"
                              id="excel-upload"
                              name="excel-upload"
                              type="file"
                              accept=".xlsx, .xls"
                              onChange={onChangeExcelFile}
                            />
                          </div>
                          <p
                            className="mt-1 text-xs font-normal text-gray-500 dark:text-gray-300"
                            id="file_input_help"
                          >
                            Taslak davetli listesini indirmek için{" "}
                            <strong className="font-semibold text-gray-900 dark:text-white">
                              <a href="https://firebasestorage.googleapis.com/v0/b/multi-tenant-rsvp.appspot.com/o/davetli-listesi-sablon.xlsx?alt=media">
                                buraya
                              </a>
                            </strong>{" "}
                            tıklayınız. Dosyanızı .xlsx uzantısı ile yükleyin.
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="flex justify-end mt-8">
                <button
                  onClick={() => {
                    submitGuestForm();
                  }}
                  className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  Kaydet
                  <svg
                    className="w-3.5 h-3.5 ml-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </button>
              </div>
            </div>

            {/* Send Message Modal  */}
            <div
              id="sendModal"
              tabIndex="-1"
              aria-hidden="true"
              className={`flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full${
                showSendConfirmationModal ? "" : " hidden"
              }`}
            >
              <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                {/* <!-- Modal content --> */}
                <div
                  className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5"
                  data-modal-backdrop="static"
                >
                  <button
                    type="button"
                    className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="sendModal"
                    onClick={() => setShowSendConfirmationModal(false)}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <svg
                    className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.922 11.76a1.56 1.56 0 0 0-.551-1.208L11.264 6.3a1.35 1.35 0 0 0-1.473-.2 1.542 1.542 0 0 0-.872 1.427v1.221a6.922 6.922 0 0 0-6 7.134v1.33A1.225 1.225 0 0 0 4.143 18.5a1.187 1.187 0 0 0 1.08-.73 4.72 4.72 0 0 1 3.7-2.868v1.085a1.546 1.546 0 0 0 .872 1.428 1.355 1.355 0 0 0 1.472-.2l5.108-4.25a1.56 1.56 0 0 0 .547-1.206Z" />
                    <path d="m21.428 10.205-5.517-4.949a1 1 0 1 0-1.336 1.488l5.517 5.014-5.611 5.088a1 1 0 1 0 1.344 1.482l5.611-5.088a2.049 2.049 0 0 0-.008-3.035Z" />
                  </svg>
                  <p className="mb-4 text-gray-500 dark:text-gray-300">
                    Göndermek istediğinize emin misiniz ?
                  </p>
                  <div className="flex justify-center items-center space-x-4">
                    <button
                      type="button"
                      onClick={() => setShowSendConfirmationModal(false)}
                      className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Hayır, iptal et
                    </button>
                    <button
                      type="submit"
                      onClick={handleSendMessages}
                      className="py-2 px-3 text-sm font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900"
                    >
                      Evet, eminim
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Delete Modal  */}
            <div
              id="deleteModal"
              tabIndex="-1"
              aria-hidden="true"
              className={`flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full${
                showDeleteModal ? "" : " hidden"
              }`}
            >
              <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                {/* <!-- Modal content --> */}
                <div
                  className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5"
                  data-modal-backdrop="static"
                >
                  <button
                    type="button"
                    className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="deleteModal"
                    onClick={() => {
                      setShowDeleteModal(false);
                      setDeleteModalData(null);
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <svg
                    className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.922 11.76a1.56 1.56 0 0 0-.551-1.208L11.264 6.3a1.35 1.35 0 0 0-1.473-.2 1.542 1.542 0 0 0-.872 1.427v1.221a6.922 6.922 0 0 0-6 7.134v1.33A1.225 1.225 0 0 0 4.143 18.5a1.187 1.187 0 0 0 1.08-.73 4.72 4.72 0 0 1 3.7-2.868v1.085a1.546 1.546 0 0 0 .872 1.428 1.355 1.355 0 0 0 1.472-.2l5.108-4.25a1.56 1.56 0 0 0 .547-1.206Z" />
                    <path d="m21.428 10.205-5.517-4.949a1 1 0 1 0-1.336 1.488l5.517 5.014-5.611 5.088a1 1 0 1 0 1.344 1.482l5.611-5.088a2.049 2.049 0 0 0-.008-3.035Z" />
                  </svg>
                  <p className="mb-4 text-gray-500 dark:text-gray-300">
                    {deleteModalData} nolu davetliyi silmek istediğinizden emin
                    misiniz ?
                  </p>
                  <div className="flex justify-center items-center space-x-4">
                    <button
                      type="button"
                      onClick={() => {
                        setShowDeleteModal(false);
                        setDeleteModalData(null);
                      }}
                      className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Hayır, iptal et
                    </button>
                    <button
                      type="submit"
                      onClick={handleDeleteButton}
                      className="py-2 px-3 text-sm font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900"
                    >
                      Evet, eminim
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );

  function renderStatusBadge(status) {
    let badgeColor = "";
    let badgeText = "";

    switch (status) {
      case "pending":
        badgeColor = "bg-yellow-500";
        badgeText = "Gönderim bekleniyor";
        break;
      case "invitation-sent":
        badgeColor = "bg-green-200";
        badgeText = "Davetiye gönderildi";
        break;
      case "attendance-inquiry":
        badgeColor = "bg-yellow-300";
        badgeText = "Katılım durumu bekleniyor";
        break;
      case "attendance-accepted":
        badgeColor = "bg-green-400";
        badgeText = "Kişi sayısı bekleniyor";
        break;
      case "attendance-accepted-with-guests":
        badgeColor = "bg-green-500";
        badgeText = "Katılacak";
        break;
      case "attendance-accepted-with-guests-infos":
        badgeColor = "bg-green-500";
        badgeText = "Katılacak";
        break;
      case "attendance-rejected":
        badgeColor = "bg-red-500";
        badgeText = "Katılmayacak";
        break;
      default:
        break;
    }

    return (
      <>
        <div className={`w-3 h-3 mr-2 border rounded-full ${badgeColor}`}></div>
        {badgeText}
      </>
    );
  }

  function renderMessageStatusBadge(eventId, status) {
    let badgeColor = "";
    let badgeText = "";

    switch (status) {
      case "read":
        badgeColor = "text-green-500";
        badgeText = "OK";
        break;
      case "read-undefined":
        badgeColor = "text-green-500";
        badgeText = "OK";
        break;
      case "received":
        badgeColor = "text-green-500";
        badgeText = "OK";
        break;
      case "received-undefined":
        badgeColor = "text-green-500";
        badgeText = "OK";
        break;
      case "sent":
        badgeColor = "text-green-500";
        badgeText = "OK";
        break;
      case "sent-undefined":
        badgeColor = "text-green-500";
        badgeText = "OK";
        break;
      case "delivered":
        badgeColor = "text-green-500";
        badgeText = "OK";
        break;
      case "delivered-undefined":
        badgeColor = "text-green-500";
        badgeText = "OK";
        break;
      default:
        badgeColor = "text-red-500";
        badgeText = `HATA: ${status}`;
        break;
    }

    return <div className={`whitespace-normal	 ${badgeColor}`}>{status}</div>;

    // if (
    //   eventId === "L6xftux195fhqLKlpvE1" ||
    //   eventId === "3T9AoLPLBOYLyHcFILL6" ||
    //   eventId === "E9Myjtrb8mdkvuQ7ltQs"
    // ) {
    //   return <div className={`whitespace-normal	 ${badgeColor}`}>{status}</div>;
    // } else {
    //   return (
    //     <div className={`whitespace-normal	 ${badgeColor}`}>{badgeText}</div>
    //   );
    // }
  }

  function getFormattedTimestamp(timestamp = null) {
    let date;
    if (timestamp) {
      date =
        timestamp instanceof Date ? timestamp : new Date(timestamp.toDate());
    } else {
      date = new Date();
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
}

export default EventDetail;
