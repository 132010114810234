import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

// This component will wrap around any Route that needs to be protected
function ProtectedRoute({ children, requiredRoles }) {
  const { currentUser, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    // You can return null or a loading spinner here
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    // If user is not logged in, redirect to the login page
    return <Navigate to="/giris" state={{ from: location }} replace />;
  }

  if (requiredRoles && !requiredRoles.includes(currentUser.role)) {
    // If user doesn't have required role, redirect them to a NoMatch or a specific "unauthorized" route
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return children;
}

export default ProtectedRoute;
