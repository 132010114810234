import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Dropdown } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import DatePicker from "tailwind-datepicker-react";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../AuthContext";
import {
  createEvent,
  deleteEvent,
  getEvents,
  getOrganizationDocById,
  storage,
} from "../../firebase";
import Places from "../../utils/Places";
import getSubdomain from "../../utils/getSubdomain";

function EventsPage() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [credit, setCredit] = useState(null);

  //  filter state
  const [filter, setFilter] = useState({
    active: false,
    pending: false,
    search: "",
  });
  const [filteredEvents, setFilteredEvents] = useState(events);

  // event form data starts
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const emptyEvent = {
    eventName: "",
    credit: "",
    category: "wedding",
    status: "pending",
    groomName: "",
    brideName: "",
    eventDate: "",
    eventFileUrl: "",
    eventFileType: "",
    locationName: "",
    location: "",
  };
  const [eventFormData, setEventFormData] = useState(emptyEvent);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState("19:00");
  const [eventFile, setEventFile] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerOptions = {
    minDate: new Date().setDate(new Date().getDate() - 1),
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    inputDateFormatProp: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
    theme: {
      todayBtn: "bg-primary-800",
      defaultValue: "bg-primary-800",
      disabledText: "opacity-50",
    },
    language: "tr",
    weekDays: ["Pzt", "Sal", "Çar", "Per", "Cum", "Cmt", "Paz"],
    datepickerClassNames: "top-34 ",
  };

  useEffect(() => {
    const [hours, minutes] = eventTime.split(":").map(Number);
    var originalDate = new Date(eventDate);
    originalDate.setHours(hours, minutes, "00");
    setEventFormData({
      ...eventFormData,
      eventDate: originalDate,
    });
  }, [eventDate, eventTime]);

  // upload firebase event file
  const [fileUploadSuccess, setFileUploadSuccess] = useState(true);
  useEffect(() => {
    if (eventFile) {
      uploadFile(eventFile);
    }
  }, [eventFile]);

  const uploadFile = async (eventFile) => {
    const extension = eventFile.name.split(".").pop();

    if (["png", "jpg", "jpeg", "mp4"].includes(extension)) {
      setFileUploadSuccess(false);

      const fileName = new Date().getTime() + "_" + uuidv4() + "." + extension;
      const storageRef = ref(storage, "invitationFile/" + fileName);

      try {
        const snapshot = await uploadBytes(storageRef, eventFile);
        const downloadURL = await getDownloadURL(snapshot.ref);

        toast.success("Davetiye yüklendi");
        setEventFormData({
          ...eventFormData,
          eventFileUrl: downloadURL,
          eventFileType: extension,
        });
        setFileUploadSuccess(true);
      } catch (error) {
        toast.error("Davetiye yükleme hatası: " + error.message);
        setFileUploadSuccess(false);
      }
    } else {
      toast.error("Lütfen belirtilen formatlarda dosya yükleyin.");
      setFileUploadSuccess(false);
    }
  };

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    const allocatedCredit = Number(eventFormData.credit);
    if (credit >= allocatedCredit) {
      if (fileUploadSuccess === true) {
        try {
          setLoading(true);

          const eventRef = await createEvent(eventFormData, currentUser);

          setShowCreateEventModal(false);

          const updatedEvents = [
            { ...eventFormData, id: eventRef.id },
            ...events,
          ];
          setEvents(updatedEvents);

          setEventFormData(emptyEvent);
          setLoading(false);
        } catch (error) {
          toast.error(
            "Etkinlik oluşturulurken bir hata oluştu, lütfen tekrar deneyin."
          );
        }
      } else {
        toast.error("Lütfen davetinin yüklenmesini bekleyin...");
      }
    } else {
      toast.error("Kredi miktarınız yetersiz!");
    }
  };
  // event form data ends

  // get organization data and set credit on page load
  useEffect(() => {
    if (currentUser?.organizationId) {
      getOrganizationDocById(currentUser.organizationId).then(
        (organizationData) => {
          setCredit(organizationData.credits);
        }
      );
    }
  }, [currentUser?.organizationId]);

  // get all events under the organization on page load
  useEffect(() => {
    console.log(currentUser);
    getEvents(currentUser.organizationId).then((events) => {
      // if currentUser.uid is equal to TZDu6ZR7iEbLgUV20jUaOTCOpX12, then only show the events whose ids are Eo4G2vdRd7R7PwKODnov, eErmnKIG8UvoMeZ7NfPT and veKyjWKWz5jHMMhh7c0O
      if (currentUser.uid === "TZDu6ZR7iEbLgUV20jUaOTCOpX12") {
        const filteredEvents = events.filter((event) =>
          [
            "Eo4G2vdRd7R7PwKODnov",
            "eErmnKIG8UvoMeZ7NfPT",
            "veKyjWKWz5jHMMhh7c0O",
            "VdHpOuBOKDlpJcQRKEzI",
          ].includes(event.id)
        );
        setEvents(filteredEvents);
      } else {
        setEvents(events);
      }
      setLoading(false);
    });
  }, [currentUser.organizationId]);

  // copy event data
  const copyEvent = (event) => {
    if (event.status !== "pending") {
      const subDomain = getSubdomain();

      navigator.clipboard.writeText(`
      Giriş Linki: http://${subDomain}.rsvpglobal.org/etkinlik/${event.id}
      Kullanıcı Adı: ${event.id}
      Email: ${event.id}
      `);
      toast.success("Bilgiler başarıyla kopyalandı.");
    } else {
      toast.error("Etkinlik oluşturuluyor, lütfen bekleyiniz.");
    }
  };

  // delete event
  const [willBeDeletedEvent, setWillBeDeletedEvent] = useState(null);

  const handleDeleteEvent = async () => {
    const result = await deleteEvent(willBeDeletedEvent.id);
    if (result) {
      // Filter out the event with the matching ID
      const updatedEvents = events.filter(
        (event) => event.id !== willBeDeletedEvent.id
      );

      // Update the state with the new array of events
      setEvents(updatedEvents);
    }

    setWillBeDeletedEvent(null);

    if (result) {
      toast.success("Etkinlik başarıyla silindi");
    } else {
      toast.error("Etkinlik bulunamadı!");
    }
  };

  // Filter
  const applyFilter = () => {
    const filtered = events.filter((event) => {
      return (
        ((!filter.active && !filter.pending) ||
          (filter.active && event.status !== "pending") ||
          (filter.pending && event.status === "pending")) &&
        event.eventName.toLowerCase().includes(filter.search.toLowerCase())
      );
    });

    setFilteredEvents(filtered);
  };

  useEffect(() => {
    applyFilter();
  }, [filter, events]);

  return (
    <>
      <Helmet>
        <title>Etkinlikler | rsvpglobal.org</title>
      </Helmet>
      <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden lg:overflow-visible ">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
              <div className="w-full md:w-1/2">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Etkinlik Ara
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      value={filter.search}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          search: e.target.value,
                        })
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Ara..."
                    />
                  </div>
                </form>
              </div>
              <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <button
                  type="button"
                  onClick={() => setShowCreateEventModal(true)}
                  className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  <svg
                    className="h-3.5 w-3.5 mr-2 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 1v16M1 9h16"
                    />
                  </svg>
                  Yeni Etkinlik
                </button>
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <Dropdown
                    arrowIcon={false}
                    dismissOnClick={false}
                    inline
                    label={
                      <span
                        id="filterDropdownButton"
                        data-dropdown-toggle="filterDropdown"
                        className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          className="h-4 w-4 mr-1.5 -ml-1 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Filtreler
                        <svg
                          className="-mr-1 ml-1.5 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          />
                        </svg>
                      </span>
                    }
                  >
                    <div
                      id="filterDropdown"
                      className="z-10 w-56 p-3 bg-white rounded-lg dark:bg-gray-700"
                    >
                      <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">
                        Durum
                      </h6>
                      <ul
                        className="space-y-2 text-sm"
                        aria-labelledby="dropdownDefault"
                      >
                        <li className="flex items-center">
                          <input
                            id="active"
                            type="checkbox"
                            onChange={() =>
                              setFilter({
                                ...filter,
                                active: !filter.active,
                              })
                            }
                            checked={filter.active}
                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                          <label
                            htmlFor="active"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                          >
                            Aktif
                          </label>
                        </li>
                        <li className="flex items-center">
                          <input
                            id="pending"
                            type="checkbox"
                            onChange={() =>
                              setFilter({
                                ...filter,
                                pending: !filter.pending,
                              })
                            }
                            checked={filter.pending}
                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                          <label
                            htmlFor="pending"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                          >
                            Oluşturuluyor
                          </label>
                        </li>
                      </ul>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="overflow-x-auto">
              {loading ? (
                <div className="flex items-center justify-center border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <div role="status" className="my-2">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <>
                  {events.length === 0 ? (
                    <div className=" flex justify-center py-5">
                      <p className="text-gray-500 dark:text-gray-400">
                        İlk etkinliğinizi oluşturmak için{" "}
                        <button
                          type="button"
                          onClick={() => setShowCreateEventModal(true)}
                          className="font-medium text-primary-600 underline dark:text-blue-500 hover:no-underline"
                        >
                          Buraya
                        </button>{" "}
                        tıklayın.
                      </p>
                    </div>
                  ) : (
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-4 py-3">
                            Etkinlik ismi
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Kategori
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Ayrılan Kredi
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Kalan Kredi
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Durum
                          </th>
                          <th scope="col" className="px-4 py-3">
                            <span className="sr-only">İşlemler</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredEvents.map((event, index) => (
                          <tr
                            className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                            key={index}
                          >
                            <th
                              scope="row"
                              className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <Link
                                to={`/etkinlik/${event.id}`}
                                className="contents"
                              >
                                <div className="flex items-center">
                                  {event.eventName}
                                  {event.custom ? (
                                    <span className="ml-2 bg-gray-100 text-gray-800 text-xs font-medium me-2 px-1 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
                                      Custom
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Link>
                            </th>

                            <td className="px-4 py-2">
                              <div className="inline-flex items-center bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3.5 w-3.5 mr-1"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                  />
                                </svg>
                                {event.category === "wedding" ? "Düğün" : "???"}
                              </div>
                            </td>

                            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <div className="flex items-center">
                                {event.credits}
                              </div>
                            </td>

                            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <div className="flex items-center">
                                {event.creditsRemained}
                              </div>
                            </td>

                            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <div className="flex items-center">
                                <div
                                  className={`w-3 h-3 mr-2 ${
                                    event.status === "pending"
                                      ? "bg-orange-500"
                                      : "bg-green-500"
                                  } border rounded-full`}
                                ></div>
                                {event.status === "pending"
                                  ? "Oluşturuluyor"
                                  : "Aktif"}
                              </div>
                            </td>

                            <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <button onClick={() => copyEvent(event)}>
                                <svg
                                  className="w-5 h-5 text-primary-800 "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 18 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m7.708 2.292.706-.706A2 2 0 0 1 9.828 1h6.239A.97.97 0 0 1 17 2v12a.97.97 0 0 1-.933 1H15M6 5v4a1 1 0 0 1-1 1H1m11-4v12a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V9.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 5h5.239A.97.97 0 0 1 12 6Z"
                                  />
                                </svg>
                              </button>

                              <button
                                type="button"
                                onClick={() => setWillBeDeletedEvent(event)}
                              >
                                <svg
                                  class="ml-4 w-5 h-5 text-primary-800"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 18 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/* New Event Modal */}
        {/* bg black */}
        {(showCreateEventModal || willBeDeletedEvent) && (
          <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-50"></div>
        )}

        <div
          id="createEventModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full ${
            showCreateEventModal ? "flex" : " hidden"
          }`}
        >
          <div className="relative p-4 w-full max-w-5xl h-full ">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center mb-4 sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Etkinlik Oluştur
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="createEventModal"
                  onClick={() => setShowCreateEventModal(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <form onSubmit={(e) => handleCreateEvent(e)}>
                <div className="grid gap-4 mb-4 lg:grid-cols-2 sm:mb-6">
                  <div className="space-y-4">
                    <div>
                      <label
                        htmlFor="title"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        Etkinlik İsmi
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        className="bg-gray-50 border border-gray-300 text-sm text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={eventFormData.eventName}
                        onChange={(e) =>
                          setEventFormData({
                            ...eventFormData,
                            eventName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="credit"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        Ayrılan Kredi Miktarı
                      </label>
                      <input
                        type="number"
                        name="credit"
                        id="credit"
                        className="bg-gray-50 border border-gray-300 text-sm text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={eventFormData.credit}
                        onChange={(e) =>
                          setEventFormData({
                            ...eventFormData,
                            credit: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="brideName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        Gelin
                      </label>
                      <input
                        type="text"
                        name="brideName"
                        id="brideName"
                        className="bg-gray-50 border border-gray-300 text-sm text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={eventFormData.brideName}
                        onChange={(e) =>
                          setEventFormData({
                            ...eventFormData,
                            brideName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="groomName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        Damat
                      </label>
                      <input
                        type="text"
                        name="groomName"
                        id="groomName"
                        className="bg-gray-50 border border-gray-300 text-sm text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={eventFormData.groomName}
                        onChange={(e) =>
                          setEventFormData({
                            ...eventFormData,
                            groomName: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white after:content-['*'] after:ml-0.5 after:text-red-500">
                        Etkinlik Tarihi
                      </label>
                      <DatePicker
                        show={showDatePicker}
                        setShow={(state) => setShowDatePicker(state)}
                        options={datePickerOptions}
                        value={eventFormData.eventDate}
                        dateFormat="MM-dd-yyyy"
                        onChange={(e) => {
                          setEventDate(e);
                        }}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="eventTime"
                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-white after:content-['*'] after:ml-0.5 after:text-red-500`}
                      >
                        Etkinlik Saati
                      </label>
                      <input
                        type="time"
                        value={eventTime}
                        id="eventTime"
                        name="eventTime"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        onChange={(e) => {
                          setEventTime(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <div className="sm:col-span-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white after:content-['*'] after:ml-0.5 after:text-red-500">
                          Davetiye (Görsel veya Video)
                        </label>
                        <div className="items-center w-full sm:flex ">
                          {/* {eventFile ? (
                            eventFile.type == "video/mp4" ? (
                              ""
                            ) : (
                              <img
                                src={URL.createObjectURL(eventFile)}
                                className="w-1/4 "
                                alt="chosen"
                              />
                            )
                          ) : (
                            ""
                          )} */}

                          <div className="w-full">
                            <input
                              className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                              aria-describedby="file_input_help"
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              accept=".png, .jpg, .jpeg, .mp4"
                              required
                              onChange={(e) => {
                                setEventFile(e.target.files[0]);
                              }}
                            />
                            <p
                              className="mt-1 text-xs font-normal text-gray-500 dark:text-gray-300"
                              id="file_input_help"
                            >
                              PNG, JPG, JPEG, MP4 formatlarından birini
                              yükleyebilirsiniz.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="location"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        Mekan Adı
                      </label>
                      <div>
                        <input
                          type="text"
                          id="location"
                          required
                          value={eventFormData.locationName}
                          onChange={(e) =>
                            setEventFormData({
                              ...eventFormData,
                              locationName: e.target.value,
                            })
                          }
                          className="bg-gray-50 border border-gray-300 text-sm text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        />
                      </div>
                    </div>
                    {/* Venue Location */}
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white after:content-['*'] after:ml-0.5 after:text-red-500">
                        Mekan Konumu
                      </label>
                      <div>
                        {
                          <Places
                            setSelected={(selectedLocation) => {
                              setEventFormData({
                                ...eventFormData,
                                location: selectedLocation,
                              });
                            }}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    type="submit"
                    className="text-white inline-flex items-center justify-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    <svg
                      aria-hidden="true"
                      className="-ml-1 w-5 h-5 sm:mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Etkinliği Oluştur
                    <span className="sr-only">Etkinliği Oluştur</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Delete Event Modal  */}
        <div
          id="deleteModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full${
            willBeDeletedEvent ? "" : " hidden"
          }`}
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            {/* <!-- Modal content --> */}
            <div
              className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5"
              data-modal-backdrop="static"
            >
              <button
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="deleteModal"
                onClick={() => setWillBeDeletedEvent(null)}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <svg
                class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>

              <p className="mb-4 text-gray-500 dark:text-gray-300">
                Etkinliği silmek istediğinizden emin misiniz?
              </p>
              <div className="flex justify-center items-center space-x-4">
                <button
                  type="button"
                  onClick={() => setWillBeDeletedEvent(null)}
                  className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Hayır, iptal et
                </button>
                <button
                  type="submit"
                  onClick={handleDeleteEvent}
                  className="py-2 px-3 text-sm font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900"
                >
                  Evet, eminim
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EventsPage;
