import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import CreditPage from "./pages/Dashboard/CreditPage";
import DashboardLayout from "./pages/Dashboard/DashboardLayout";
import EventDetailPage from "./pages/Dashboard/EventDetailPage";
import EventsPage from "./pages/Dashboard/EventsPage";
import HomePage from "./pages/Dashboard/HomePage";
import PaymentResultPage from "./pages/Dashboard/PaymentResultPage";
import LoginPage from "./pages/LoginPage";
import NoMatch from "./pages/NoMatch";
import ProtectedRoute from "./ProtectedRoute";
import getSubdomain from "./utils/getSubdomain";

function App() {
  const [subdomain, setSubdomain] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setSubdomain(getSubdomain());
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <Router>
        <Toaster />
        <div className="App">
          {subdomain ? (
            <AuthProvider>
              <Routes>
                <Route
                  path="/giris"
                  element={<LoginPage subdomain={subdomain} />}
                />
                <Route path="/" element={<DashboardLayout />}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        requiredRoles={["admin", "organization", "event"]}
                      >
                        <HomePage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="etkinlikler"
                    element={
                      <ProtectedRoute requiredRoles={["organization", "admin"]}>
                        <EventsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="kredi"
                    element={
                      <ProtectedRoute requiredRoles={["organization", "admin"]}>
                        <CreditPage />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/odeme-sonucu/:paymentId"
                    element={
                      <ProtectedRoute requiredRoles={["organization", "admin"]}>
                        <PaymentResultPage />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="etkinlik/:eventId"
                    element={
                      <ProtectedRoute
                        requiredRoles={["organization", "event", "admin"]}
                      >
                        <EventDetailPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </AuthProvider>
          ) : (
            <Routes>
              <Route path="*" element={<NoMatch />} />
            </Routes>
          )}
        </div>
      </Router>
    );
  }
}

export default App;
