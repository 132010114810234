import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useAuth } from "../../AuthContext";

function Payment() {
  const { currentUser } = useAuth();

  const [credit, setCredit] = useState(100);
  const [loading, setLoading] = useState(false);

  const handleCreditChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setCredit(newValue);
  };

  const handlePayment = () => {
    setLoading(true); // Set loading to true when payment starts
    axios
      .get("https://api64.ipify.org?format=json")
      .then((response) => {
        const ipAddress = response.data.ip;

        const apiUrl =
          "https://us-central1-multi-tenant-rsvp.cloudfunctions.net/app/payment";
        const paymentData = {
          user_id: currentUser.uid,
          organization_id: currentUser.organizationId,
          ip_address: ipAddress,
          credit_amount: credit,
        };

        axios
          .post(apiUrl, paymentData, {
            headers: {
              Authorization: `Bearer ${currentUser.accessToken}`,
            },
          })
          .then((response) => {
            toast.success("Ödeme Sayfasına Yönlendiriliyorsunuz.");
            window.location.href = response.data.paymentPageUrl;
          })
          .catch((error) => {
            console.error("Ödeme hatası:", error);
            toast.error("Ödeme işlemi sırasında bir hata oluştu.");
          })
          .finally(() => {
            setLoading(false); // Set loading to false when payment completes
          });
      })
      .catch((error) => {
        console.error("IP alınamadı:", error);
        toast.error("IP adresi alınamadı. Lütfen tekrar deneyin.");
        setLoading(false); // Set loading to false on error
      });
  };

  return (
    <>
      <Helmet>
        <title>Kredi | rsvpglobal.org</title>
      </Helmet>
      {/* Pricing Card */}
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="bg-white rounded-lg shadow lg:grid lg:grid-cols-3 dark:bg-gray-800">
          <div className="col-span-2 p-6 lg:p-8">
            <h2 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              Her bir davetli için 1 krediye ihtiyacınız vardır.
            </h2>
            <div className="mt-4">
              <h3 className="text-2xl sm:text-3xl font-extrabold text-gray-900 dark:text-white">
                Kredi Miktarı:
                <span id="creditValue" className="text-primary-600">
                  {" "}
                  {credit}
                </span>
              </h3>
            </div>
            <div className="grid gap-4 mt-4 lg:mt-6">
              <input
                id="large-range"
                type="range"
                value={credit}
                className="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
                step="10"
                min="10"
                max="500"
                onChange={handleCreditChange}
              />
            </div>

            <p className="text-lg font-light text-gray-500 dark:text-gray-400 mt-4">
              Her bir WhatsApp numarasına davetiye göndermek ve LCV sürecini
              başlatmak için 1 Kredi gerekmektedir.
            </p>
          </div>
          <div className="flex p-6 text-center bg-gray-50 lg:p-8 dark:bg-gray-700">
            <div className="self-center w-full">
              <div className="text-5xl flex items-center justify-center font-extrabold text-gray-900 dark:text-white">
                {credit * 30}₺<span className="text-2xl"> + KDV</span>
              </div>
              <div className="mt-1 mb-4 text-gray-500 text-light dark:text-gray-400">
                1 kredi = 1 davetli
              </div>
              <a
                href="#"
                className="flex justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-bue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={handlePayment}
              >
                Satın Al
              </a>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-20 z-50">
          <div role="status" className="flex items-center gap-2">
            <svg
              aria-hidden="true"
              className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Payment;
