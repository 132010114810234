import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/rsvp-global-logo.png";
import { useAuth } from "../AuthContext";
import { signIn } from "../firebase";

function Login({ subdomain }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    // Check if the user is already logged in
    if (currentUser) {
      // Redirect based on role
      if (currentUser.role === "event") {
        navigate("/etkinlik/" + currentUser.eventId);
      } else if (currentUser.role === "organization") {
        navigate("/etkinlikler");
      }
    }
  }, [currentUser, navigate]);

  //login function
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let email;
      if (!userName.includes("@")) {
        email = userName + "@rsvp-global.com";
      } else {
        email = userName;
      }
      const result = await signIn(email, password, subdomain);
      if (!result.success) {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Giriş | rsvpglobal.org</title>
      </Helmet>
      <section className="bg-gray-50 dark:bg-gray-900 h-full  flex justify-center items-center">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-20 lg:py-16 lg:grid-cols-12 h-screen">
          <div className="w-full place-self-center lg:col-span-6">
            <div className="p-6 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 sm:max-w-xl sm:p-8">
              <a
                href="#"
                className="inline-flex items-center mb-6 text-xl font-semibold text-gray-900 dark:text-white justify-center"
              >
                {/* Logo */}
                <img className="w-3/6" src={Logo} alt="RSVP Global Logo" />
              </a>
              <h1 className="mb-2 text-2xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
                Hoş geldin
              </h1>
              <form className="mt-4 space-y-6 sm:mt-6" onSubmit={handleLogin}>
                <div className="flex flex-col gap-5">
                  <div>
                    <label
                      htmlFor="userName"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Kullanıcı Adı
                    </label>
                    <input
                      id="userName"
                      name="userName"
                      type="userName"
                      autoComplete="userName"
                      required
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Parola
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Giriş Yap
                </button>
              </form>
            </div>
          </div>
          <div className="mr-auto place-self-center lg:col-span-6">
            <img
              className="hidden mx-auto lg:flex"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/authentication/illustration.svg"
              alt="illustration"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
